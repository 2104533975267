import React from 'react';
import { UncontrolledTooltip, Input, Button } from 'reactstrap';
import { MdDownload } from 'react-icons/md';
import { GoDiff } from 'react-icons/go';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Routes } from '../../../../../App.constants';
import DateTableCell from '../../../../../components/DateTableCell/DateTableCell';

import styles from './FeatureDetailVersionsTable.module.scss';

/**
 * @param  {string} featureId - feature ID
 * @param  {Object} selectedVersionsToCompare - an object containing the current versions selected - format -> {rowName: versionNumber}
 * @param  {function} setSelectedVersionsToCompare - setter method for selectedVersionsToCompare
 * @param  {func} handleEditIconClick - click handler for the edit icon
 * @param {func} setDownload - click handler for download
 * @return  {array} - array of column data for react-table
 */
const featureVersionsTableColumns = (
  featureId,
  selectedVersionsToCompare,
  setSelectedVersionsToCompare,
  compareVersionsUrl,
  setDownload
) => [
  {
    Header: () => {
      if (compareVersionsUrl) {
        return (
          <a
            role="button"
            href={compareVersionsUrl}
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-primary"
            data-testid="compare-btn-enabled"
            id="compareVersionEnabled"
          >
            <GoDiff size={24} />
            <UncontrolledTooltip placement="right" target="compareVersionEnabled">
              Compare Versions
            </UncontrolledTooltip>
          </a>
        );
      }

      return (
        <div
          className={classnames(styles.disabledCompareBtn, 'btn btn-primary disabled')}
          role="button"
          data-testid="compare-btn-disabled"
          id="compareVersionDisabled"
        >
          <GoDiff size={24} />
          <UncontrolledTooltip placement="right" target="compareVersionDisabled">
            Compare Versions
          </UncontrolledTooltip>
        </div>
      );
    },
    id: 'compare-versions',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { row } = d;
      const { id } = row;
      const { original } = row;
      const { version } = original;

      return (
        <Input
          type="checkbox"
          id={`row-${id}-checkbox`}
          name={`row-${id}-checkbox`}
          key={`row-${id}-checkbox`}
          data-testid={`row-${id}-checkbox`}
          checked={Object.keys(selectedVersionsToCompare).includes(`row-${id}-checkbox`)}
          onChange={(e) => {
            const { target } = e;
            const { checked, name } = target;

            if (checked) {
              // If checked update the state so that this version is tracked for comparison.
              setSelectedVersionsToCompare({
                ...selectedVersionsToCompare,
                [name]: version,
              });
            } else {
              // If unchecked make a copy of the current state, remove this version from the copy
              // and update the state.
              const updatedSelectedVersionsToCompare = { ...selectedVersionsToCompare };

              delete updatedSelectedVersionsToCompare[[name]];

              setSelectedVersionsToCompare(updatedSelectedVersionsToCompare);
            }
          }}
          disabled={
            Object.keys(selectedVersionsToCompare).length === 2 &&
            !Object.keys(selectedVersionsToCompare).includes(`row-${id}-checkbox`)
          }
        />
      );
    },
  },
  {
    Header: (
      <>
        <p id="download-col-header" className="mb-0">
          Download
        </p>
        <UncontrolledTooltip placement="bottom" target="download-col-header">
          Download Feature Version
        </UncontrolledTooltip>
      </>
    ),
    accessor: 'version',
    id: 'download-version',
    disableFilters: true,
    disableSortBy: true,
    Cell: (e) => {
      const { value } = e;

      return (
        <Button onClick={() => setDownload(value)} color="link" className="me-2">
          <MdDownload size={18} />
        </Button>
      );
    },
  },
  {
    Header: 'Version',
    accessor: 'version',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { value, row } = d;
      const { id } = row;

      return (
        <Link id={`id-${id}`} to={Routes.FEATURE_VIEW.toLink({ featureId })} className="me-0">
          {value}
        </Link>
      );
    },
  },

  {
    Header: 'Updated',
    accessor: 'updated',
    disableFilters: true,
    disableSortBy: true,
    Cell: (d) => {
      const { value, row } = d;
      const { id } = row;

      return <DateTableCell id={`row${id}updated`} dateString={value?.dateMonthYear} timeString={value?.time} />;
    },
  },
];

export default featureVersionsTableColumns;
