import React from 'react';
import { Button, Input, Label } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './DefaultTableControls.module.scss';

const { pageInput, rowSelectDropdown, tableButtons } = styles;

const DefaultTableControls = ({
  previousPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
  numOfPages,
  pageSize,
  setPageSize,
  numOfRows,
}) =>
  numOfRows > 5 ? (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-center w-100 mb-3">
      <div>
        <Button className={classnames(tableButtons, 'me-2')} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          First
        </Button>
        <Button
          className={classnames(tableButtons, 'me-2')}
          color="secondary"
          onClick={previousPage}
          disabled={!canPreviousPage}
        >
          Previous
        </Button>
        <Button
          className={classnames(tableButtons, 'me-2')}
          color="secondary"
          onClick={nextPage}
          disabled={!canNextPage}
        >
          Next
        </Button>
        <Button
          className={tableButtons}
          color="secondary"
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          Last
        </Button>
      </div>
      <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center mt-2 mt-md-0">
        <div className="d-flex justify-content-center align-items-center mb-2 mb-sm-0">
          <p className="mb-0">
            Page{' '}
            <span className="fw-bold">
              {pageIndex + 1} of {numOfPages}
            </span>
          </p>
          <p className="mb-0 mx-1">|</p>
          <Label className="mb-0 me-2" for="go-to-page-input">
            Go to page:
          </Label>
          <Input
            className={classnames(pageInput, 'me-2')}
            type="number"
            id="go-to-page-input"
            defaultValue={pageIndex + 1}
            max={numOfPages}
            min="1"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                const goTopage = e.target.value ? Number(e.target.value) - 1 : 0;

                gotoPage(goTopage);
              }
            }}
          />
        </div>
        <Input
          className={rowSelectDropdown}
          type="select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          aria-label="Select rows per page"
        >
          <option value="" defaultValue>
            Show {pageSize}
          </option>

          {[5, 10, 15, 25, 50, 100, 500, 1000].map(
            (updatablePageSize) =>
              pageSize !== updatablePageSize && (
                <option key={updatablePageSize} value={updatablePageSize}>
                  Show {updatablePageSize}
                </option>
              )
          )}
        </Input>
      </div>
    </div>
  ) : null;

DefaultTableControls.propTypes = {
  numOfPages: PropTypes.number.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  gotoPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  numOfRows: PropTypes.number.isRequired,
};

export default DefaultTableControls;
