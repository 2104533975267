import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import useFeatureApi from '../../../../hooks/useFeatureApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildFeatureVersionsTableRowsData from './FeatureDetailVersions.utils';
import { API_MAX_PER_PAGE } from '../../../../App.constants';
import HelpIcon from '../../../../components/HelpIcon/HelpIcon';
import FeatureDetailVersionsTable from './FeatureDetailVersionsTable/FeatureDetailVersionsTable';

const FeatureDetailVersions = ({ featureId }) => {
  const { getFeatureVersions, featureVersionsData, errorGettingFeatureVersions, loadingFeatureVersions } =
    useFeatureApi();
  const [tableRowsData, setTableRowsData] = useState([]);
  const [download, setDownload] = useState(false);
  const noVersionsFoundMessage = <p className="mt-3">There are no versions for this feature.</p>;

  // On mount get the feature versions.
  useEffect(() => {
    getFeatureVersions(featureId);
  }, [featureId, getFeatureVersions]);

  // Once the feature versions data is returned build the versions table rows
  // data and set the applicable state.
  useEffect(() => {
    if (featureVersionsData) {
      setTableRowsData(buildFeatureVersionsTableRowsData(featureVersionsData.versions));
    }
  }, [featureVersionsData]);

  useEffect(() => {
    if (download) {
      const featureVersionData = featureVersionsData.versions.find((versionData) => versionData.version === download);
      const name = featureVersionData.name.trim();
      const { version } = featureVersionData;
      const { content } = featureVersionData;
      const filename = `${featureId}-${name}-${version}.xml`;

      const pom = document.createElement('a');
      const bb = new Blob([content], { type: 'text/plain' });

      pom.setAttribute('href', window.URL.createObjectURL(bb));
      pom.setAttribute('download', filename);

      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
      pom.draggable = true;
      pom.classList.add('dragout');

      pom.click();
      setDownload(false);
    }
  }, [download, featureId, featureVersionsData]);

  if (errorGettingFeatureVersions) {
    if (errorGettingFeatureVersions.status === 404) {
      return noVersionsFoundMessage;
    }

    return <p className="mt-3">There was an issue loading the feature&apos;s versions.</p>;
  }

  if (loadingFeatureVersions || !featureVersionsData) {
    return (
      <div className="mt-3">
        <SpinnerComponent size={spinnerSize1} setFullVh={false} />
      </div>
    );
  }

  if (tableRowsData.length === 0) {
    return noVersionsFoundMessage;
  }

  return (
    <>
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-start my-3">
        {tableRowsData.length < API_MAX_PER_PAGE ? (
          <p className="mb-0">
            {`Versions found for feature ID ${featureId}:`} <strong>{tableRowsData[0].version}</strong>
          </p>
        ) : (
          <p className="mb-0">
            {`Versions found for feature ID ${featureId}:`} <strong>{API_MAX_PER_PAGE}</strong> (API max), from{' '}
            <strong>{tableRowsData[0].version}</strong>.
          </p>
        )}

        <HelpIcon link="versions-tab" id="versionsTab" />
      </div>
      <FeatureDetailVersionsTable rowsData={tableRowsData} featureId={featureId} setDownload={setDownload} />
    </>
  );
};

FeatureDetailVersions.propTypes = {
  featureId: PropTypes.string.isRequired,
};

export default FeatureDetailVersions;
