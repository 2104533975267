import React, { useEffect } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import PropTypes from 'prop-types';

import bdsStatusArticlesPageColumns from './BDSStatusArticlesTable.column';
import { API_MAX_PER_PAGE } from '../../../../../App.constants';
import DefaultTable from '../../../../../components/DefaultTable/DefaultTable';
import stringFilterType from '../../../../../components/DefaultTable/DefaultTable.utils';
import DefaultTableControls from '../../../../../components/DefaultTableControls/DefaultTableControls';

const BDSStatusArticlesTable = ({ data }) => {
  const numOfRows = data.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns: bdsStatusArticlesPageColumns,
      filterTypes: stringFilterType,

      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(100);
  }, [setPageSize]);

  return (
    <div className="mt-3">
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div>
          {numOfRows < API_MAX_PER_PAGE ? (
            <p>
              Articles found: <strong>{numOfRows}</strong>
            </p>
          ) : (
            <p>
              Articles found: <strong>{API_MAX_PER_PAGE}</strong> (API max), from <strong>{numOfRows}</strong>.
            </p>
          )}
        </div>
      </div>
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

BDSStatusArticlesTable.defaultProps = {
  data: [],
};

BDSStatusArticlesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default BDSStatusArticlesTable;
