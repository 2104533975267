import React from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable, usePagination, useFilters } from 'react-table';

import DefaultTable from '../../../components/DefaultTable/DefaultTable';
import DefaultTableControls from '../../../components/DefaultTableControls/DefaultTableControls';
import stringFilterType from '../../../components/DefaultTable/DefaultTable.utils';
import articleDistributionListTableColumns from './ArticleDistributionListTable.columns';

const ArticleDistributionListTable = ({ articleDistributionListDataResults }) => {
  const numOfRows = articleDistributionListDataResults.length;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: articleDistributionListDataResults,
      columns: articleDistributionListTableColumns,
      filterTypes: stringFilterType,
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="mt-3">
      <p>
        Distributions found: <strong>{numOfRows}</strong>
      </p>
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

ArticleDistributionListTable.defaultProps = {
  articleDistributionListDataResults: [],
};

ArticleDistributionListTable.propTypes = {
  articleDistributionListDataResults: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ArticleDistributionListTable;
