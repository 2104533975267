import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSortBy, useTable, usePagination, useFilters } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';

import featureVersionsTableColumns from './FeatureDetailVersionsTable.columns';
import DefaultTable from '../../../../../components/DefaultTable/DefaultTable';
import DefaultTableControls from '../../../../../components/DefaultTableControls/DefaultTableControls';
import usePrevious from '../../../../../hooks/usePrevious';
import { EDITORIAL_API } from '../../../../../App.constants';
import {
  setVersionHistoryTableConfigPageIndex,
  setVersionHistoryTableConfigPageSize,
} from '../../../../../store/action-creators';

const FeatureDetailVersionsTable = ({ rowsData, featureId, setDownload }) => {
  const [selectedVersionsToCompare, setSelectedVersionsToCompare] = useState({});
  const [compareVersionsUrl, setCompareVersionsUrl] = useState(null);
  const dispatch = useDispatch();
  const pageIndexFromRedux = useSelector((state) => state.versionHistoryTablePageIndex);
  const pageSizeFromRedux = useSelector((state) => state.versionHistoryTablePageSize);

  const memoizedColumns = useMemo(
    () =>
      featureVersionsTableColumns(
        featureId,
        selectedVersionsToCompare,
        setSelectedVersionsToCompare,
        compareVersionsUrl,
        setDownload
      ),
    [compareVersionsUrl, featureId, selectedVersionsToCompare, setDownload]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      data: rowsData,
      columns: memoizedColumns,
      initialState: {
        pageIndex: pageIndexFromRedux,
        pageSize: pageSizeFromRedux,
      },
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const previousFilters = usePrevious(filters);

  // Reset the selected versions to compare when the table filter changes.
  useEffect(() => {
    // Clear the selected versions to compare when the previous filter state value is different
    // than the current value.
    if (Object.keys(selectedVersionsToCompare).length > 0 && previousFilters[0]?.value !== filters[0]?.value) {
      setSelectedVersionsToCompare({});
    }
  }, [setSelectedVersionsToCompare, filters, selectedVersionsToCompare, previousFilters]);

  // When a version is selected or unselected update compareVersionsUrl.
  useEffect(() => {
    // If two versions are selected create the compare URL and set the state.
    if (Object.keys(selectedVersionsToCompare).length === 2) {
      const selectedVersionIds = Object.keys(selectedVersionsToCompare);
      const version1 = selectedVersionsToCompare[selectedVersionIds[0]];
      const version2 = selectedVersionsToCompare[selectedVersionIds[1]];
      // The older of the two versions will need to be set as the value
      // of the query parameter "old" and the more recent version to the
      // query parmameter "new" in the compare URL. The logic below
      // is determining based on the versions selected by the user which
      // one is older and which one is newer.
      const oldVersion = version1 < version2 ? version1 : version2;
      const newVersion = oldVersion === version1 ? version2 : version1;

      setCompareVersionsUrl(`${EDITORIAL_API}/ecm/features/${featureId}/diffview?old=${oldVersion}&new=${newVersion}`);
    } else if (compareVersionsUrl !== null) {
      // Set compareVersionsUrl to null only If two versions are not selected and compareVersionsUrl is not null.
      setCompareVersionsUrl(null);
    }
  }, [selectedVersionsToCompare, featureId, compareVersionsUrl]);

  useEffect(() => {
    dispatch(setVersionHistoryTableConfigPageIndex(pageIndex));
  }, [pageIndex, dispatch]);

  useEffect(() => {
    dispatch(setVersionHistoryTableConfigPageSize(pageSize));
  }, [dispatch, pageSize]);

  return (
    <>
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={rowsData.length}
      />
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={rowsData.length}
      />
    </>
  );
};

FeatureDetailVersionsTable.defaultProps = {
  rowsData: [],
};

FeatureDetailVersionsTable.propTypes = {
  featureId: PropTypes.string.isRequired,
  rowsData: PropTypes.arrayOf(PropTypes.shape()),
  setDownload: PropTypes.func.isRequired,
};

export default FeatureDetailVersionsTable;
