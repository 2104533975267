import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import useP2PInternationalApi from '../../../../hooks/useP2PInternationalApi';
import SpinnerComponent from '../../../../components/SpinnerComponent/SpinnerComponent';
import { spinnerSize1 } from '../../../../components/SpinnerComponent/SpinnerComponent.constants';
import buildInternationalArticlesData from './InternationalStatusArticles.utils';
import InternationalStatusArticlesTable from './InternationalStatusArticlesTable/InternationalStatusArticlesTable';

const InternationalStatusArticles = ({ formData }) => {
  const {
    getInternationalArticles,
    internationalArticlesData,
    errorGettingInternationalArticles,
    gettingInternationalArticles,
  } = useP2PInternationalApi();
  const tableRowsData = internationalArticlesData
    ? buildInternationalArticlesData(internationalArticlesData?.articles)
    : null;
  const noInternationalArticlesFoundMessage = <p className="mt-3">There are no articles.</p>;

  useEffect(() => {
    const result = new Date(formData.date);

    result.setDate(result.getDate() + 1);

    getInternationalArticles({
      startedAfter: `${formData.date?.getFullYear()}-${formData.date?.getMonth() + 1}-${formData.date?.getDate()}`,
      startedBefore: `${result.getFullYear()}-${result.getMonth() + 1}-${result.getDate()}`,
      pending: formData.pending,
    });
  }, [formData.date, formData.onlyWips, formData.pending, getInternationalArticles]);

  return (
    <>
      <Helmet>
        <title>AM: International Status: Articles</title>
      </Helmet>

      {errorGettingInternationalArticles?.status === 404 && noInternationalArticlesFoundMessage}
      {errorGettingInternationalArticles?.status !== 404 && errorGettingInternationalArticles && (
        <p className="mt-3">There was an issue loading the international articles.</p>
      )}
      {(gettingInternationalArticles || !internationalArticlesData) && !errorGettingInternationalArticles && (
        <div className="mt-3">
          <SpinnerComponent size={spinnerSize1} setFullVh={false} />
        </div>
      )}
      {tableRowsData && !gettingInternationalArticles && !errorGettingInternationalArticles && (
        <InternationalStatusArticlesTable data={tableRowsData} />
      )}
    </>
  );
};

InternationalStatusArticles.propTypes = {
  formData: PropTypes.shape().isRequired,
};

export default InternationalStatusArticles;
