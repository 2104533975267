import { useCallback, useState, useEffect } from 'react';
import { stringify } from 'query-string';

import useFetchWithState from './useFetchWithState';
import { BESSIE_API, API_MAX_PER_PAGE, EDITORIAL_API } from '../App.constants';
import useAuthApi from './useAuthApi';
import buildFeatureMetadata from './utils/useFeatureApi.utils';

const useFeatureApi = () => {
  const {
    amFetchWithState: getFeatureData,
    data: featureData,
    error: errorGettingFeatureData,
    loading: loadingFeatureData,
  } = useFetchWithState();
  const {
    amFetchWithState: getFeaturesData,
    data: featuresData,
    error: errorGettingFeatures,
    loading: loadingFeatures,
    abortActiveRequest: abortGettingFeatures,
  } = useFetchWithState();
  const {
    amFetchWithState: editFeatureData,
    data: editData,
    error: errorEditingFeature,
    loading: editingFeature,
  } = useFetchWithState();
  const {
    amFetchWithState: getFeatureVersionsData,
    data: featureVersionsData,
    error: errorGettingFeatureVersions,
    loading: loadingFeatureVersions,
  } = useFetchWithState();
  const {
    amFetchWithState: addNewFeature,
    data: addFeatureData,
    error: errorAddingFeature,
    loading: addingFeature,
  } = useFetchWithState();
  const {
    amFetchWithState: getFeatureViewData,
    data: featureViewData,
    error: errorGettingFeatureViewData,
    loading: loadingFeatureViewData,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteFeatureData,
    data: deleteData,
    error: errorDeletingFeature,
    loading: deletingFeature,
  } = useFetchWithState();
  const {
    amFetchWithState: getRecentFeaturesData,
    data: recentFeaturesData,
    error: errorGettingRecentFeatures,
    loading: loadingRecentFeatures,
  } = useFetchWithState();
  const { getAuthCookies } = useAuthApi();
  const [featureMetadata, setFeatureMetadata] = useState(null);

  /**
   * @param  {string} featureId - feature ID
   */
  const getFeature = useCallback(
    (featureId) => {
      getFeatureData(`${BESSIE_API}/feature/${featureId}`);
    },
    [getFeatureData]
  );

  /**
   * @param  {Object} featureSearchFormData - data from feature search form
   */
  const getFeatures = useCallback(
    (featureSearchFormData, pageNum, pageSize) => {
      getFeaturesData(
        `${BESSIE_API}/features?${stringify(featureSearchFormData, {
          arrayFormat: 'comma',
        })}&pageNum=${pageNum}&perPage=${pageSize}`
      );
    },
    [getFeaturesData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   * @param  {string} featureId - feature ID
   */
  const editFeature = useCallback(
    (requestBody, featureId) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      editFeatureData(`${BESSIE_API}/feature/${featureId}`, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [editFeatureData, getAuthCookies]
  );

  /**
   * @param  {string} featureId - feature ID
   */
  const getFeatureVersions = useCallback(
    (featureId) => {
      getFeatureVersionsData(`${BESSIE_API}/feature/${featureId}/versions`);
    },
    [getFeatureVersionsData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const addFeature = useCallback(
    (requestBody) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      addNewFeature(`${BESSIE_API}/feature`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addNewFeature, getAuthCookies]
  );

  /**
   * @param  {string} featureId - feature ID which has to be deleted
   */
  const deleteFeature = useCallback(
    (featureId) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      deleteFeatureData(`${BESSIE_API}/feature/${featureId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteFeatureData, getAuthCookies]
  );

  /**
   * @param  {string} featureId - feature ID
   */
  const getFeatureView = useCallback(
    (featureId) => {
      getFeatureViewData(`${EDITORIAL_API}/ecm/features/${featureId}/html`);
    },
    [getFeatureViewData]
  );

  const getRecentFeatures = useCallback(() => {
    getRecentFeaturesData(`${BESSIE_API}/features/recent?perPage=${API_MAX_PER_PAGE}`);
  }, [getRecentFeaturesData]);

  // Based on feature data from response create a custom
  // data object for feature metadata section
  useEffect(() => {
    if (featureData) {
      setFeatureMetadata(buildFeatureMetadata(featureData));
    }
  }, [featureData]);

  useEffect(() => {
    if (editData) {
      setFeatureMetadata(buildFeatureMetadata(editData));
    }
  }, [editData]);

  return {
    getFeature,
    featureData,
    featureMetadata,
    errorGettingFeatureData,
    loadingFeatureData,
    getFeatures,
    featuresData,
    errorGettingFeatures,
    loadingFeatures,
    abortGettingFeatures,
    editFeature,
    editData,
    errorEditingFeature,
    editingFeature,
    getFeatureVersions,
    featureVersionsData,
    errorGettingFeatureVersions,
    loadingFeatureVersions,
    addFeature,
    addFeatureData,
    errorAddingFeature,
    addingFeature,
    getFeatureView,
    featureViewData,
    errorGettingFeatureViewData,
    loadingFeatureViewData,
    deleteFeature,
    deleteData,
    errorDeletingFeature,
    deletingFeature,
    getRecentFeatures,
    recentFeaturesData,
    errorGettingRecentFeatures,
    loadingRecentFeatures,
  };
};

export default useFeatureApi;
