import React from 'react';
import { Label, Form, FormGroup, Button, Alert, Input, FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';
import { toast } from 'react-toastify';

import useFormHandler from '../../../hooks/useFormHandler';
import styles from './IndexSearchForm.module.scss';
import { selectTheme } from '../../../utils/select-field-utils';
import SelectField from '../../../components/SelectField/SelectField';
import { STATUS_OPTIONS, INDEX_SEARCH_INITIAL_FORM_DATA } from './IndexSearchForm.constants';
import indexCreateSchema from './IndexSearchForm.schema';

const IndexSearchForm = ({ loading, onSearch, onReset, abort, setIndexSearchFormData, indexSearchFormData }) => {
  const { input, alert } = styles;
  const {
    formData,
    formError,
    handleResetForm,
    setFormError,
    handleSelectChange,
    handleInputChange,
    isFormEmpty,
    isFormValid,
    validationErrors,
  } = useFormHandler(INDEX_SEARCH_INITIAL_FORM_DATA, indexSearchFormData, setIndexSearchFormData);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name === '' && formData.corpusId === null && !formData.corpus && formData.indexed !== null) {
      toast.error('Name or Title and/or Corpus must be entered before searching');
    } else if (!isFormEmpty()) {
      setFormError('');

      if (isFormValid(indexCreateSchema)) {
        onSearch(formData);
      }
    }
  };

  const onFormReset = (e) => {
    e.preventDefault();

    onReset();
    handleResetForm();
  };

  return (
    <Form onSubmit={handleSubmit} data-testid="indexSearchForm" onReset={onFormReset}>
      <div className="d-flex flex-wrap align-items-center justify-content-center">
        <FormGroup className={validationErrors?.name ? 'me-3 mt-3' : 'me-3'}>
          <Label for="name">Name or Title:</Label>
          <Input
            id="name"
            name="name"
            className={classnames(input, 'me-3')}
            onChange={handleInputChange}
            placeholder="Name or Title..."
            value={formData.name}
            invalid={!!validationErrors?.name}
            disabled={!!formData.topicId || !!formData.articleId}
          />
          <FormFeedback>{validationErrors.name}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="corpus">Corpus:</Label>
          <SelectField
            onChange={handleSelectChange}
            name="corpus"
            isMulti={false}
            id="corpus"
            placeholder="Select Corpus..."
            reduxKey="corpusTypes"
            valueKey="corpusId"
            value={formData.corpus}
            labelKey="name"
            endpoint="/articles/corpustypes"
            isDisabled={!!formData.topicId || !!formData.articleId}
          />
        </FormGroup>
        <FormGroup>
          <Label for="indexed">Status:</Label>
          <Select
            name="indexed"
            value={formData?.indexed}
            inputId="indexed"
            onChange={handleSelectChange}
            options={STATUS_OPTIONS}
            placeholder="Select status..."
            className={classnames(input, 'me-3')}
            theme={selectTheme}
            isDisabled={!!formData.topicId || !!formData.articleId}
          />
        </FormGroup>
        <FormGroup className="me-3">
          <Label for="articleId">Article ID:</Label>
          <Input
            id="articleId"
            name="articleId"
            className={classnames(input, 'me-3')}
            onChange={handleInputChange}
            invalid={!!validationErrors?.articleId}
            placeholder="Article ID..."
            disabled={!!formData.topicId || !!formData.corpus || !!formData.indexed || !!formData.name}
            value={formData.articleId}
          />
          <FormFeedback>{validationErrors.articleId}</FormFeedback>
        </FormGroup>
        <FormGroup className="me-3">
          <Label for="topicId">Topic ID:</Label>
          <Input
            id="topicId"
            name="topicId"
            className={classnames(input, 'me-3')}
            onChange={handleInputChange}
            invalid={!!validationErrors?.topicId}
            placeholder="Topic ID..."
            disabled={!!formData.articleId || !!formData.corpus || !!formData.indexed || !!formData.name}
            value={formData.topicId}
          />
        </FormGroup>

        <div className="d-flex justify-content-end align-items-center mt-2">
          <Button type="submit" color="primary" className="mx-2" disabled={loading}>
            {loading ? 'Searching...' : 'Search'}
          </Button>
          {loading && (
            <Button type="button" color="link" onClick={abort}>
              Cancel
            </Button>
          )}
          {!loading && (
            <Button type="reset" color="link">
              Reset
            </Button>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-3">
        <Alert
          color="danger"
          className={classnames(alert, 'mb-0')}
          isOpen={!!formError}
          toggle={() => setFormError('')}
          fade={false}
        >
          {formError}
        </Alert>
      </div>
    </Form>
  );
};

IndexSearchForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSearch: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  abort: PropTypes.func.isRequired,
  setIndexSearchFormData: PropTypes.func.isRequired,
  indexSearchFormData: PropTypes.shape().isRequired,
};

export default IndexSearchForm;
