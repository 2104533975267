import { useCallback, useEffect, useState } from 'react';
import { stringify } from 'query-string';

import useFetchWithState from './useFetchWithState';
import { BESSIE_API, API_MAX_PER_PAGE, EDITORIAL_API } from '../App.constants';
import buildArticleMetadata from './utils/useArticleApi.utils';
import useAuthApi from './useAuthApi';

const useArticleApi = () => {
  const {
    amFetchWithState: getArticlesData,
    data: articlesData,
    error: errorGettingArticles,
    loading: loadingArticles,
    abortActiveRequest: abortGettingArticles,
  } = useFetchWithState();
  const {
    amFetchWithState: getArticleData,
    data: articleData,
    error: errorGettingArticleData,
    loading: loadingArticleData,
  } = useFetchWithState();
  const {
    amFetchWithState: addNewArticle,
    data: addArticleData,
    error: errorAddingArticle,
    loading: addingArticle,
  } = useFetchWithState();
  const {
    amFetchWithState: editArticleData,
    data: editData,
    error: errorEditingArticle,
    loading: editingArticle,
  } = useFetchWithState();
  const {
    amFetchWithState: deleteArticleData,
    data: deleteData,
    error: errorDeletingArticle,
    loading: deletingArticle,
  } = useFetchWithState();
  const {
    amFetchWithState: updateArticleVersionData,
    data: updateData,
    error: errorUpdatingArticle,
    loading: updatingArticle,
  } = useFetchWithState();
  const {
    amFetchWithState: getArticleViewData,
    data: articleViewData,
    error: errorGettingArticleViewData,
    loading: loadingArticleViewData,
  } = useFetchWithState();
  const {
    amFetchWithState: getRecentArticlesData,
    data: recentArticlesData,
    error: errorGettingRecentArticles,
    loading: loadingRecentArticles,
  } = useFetchWithState();
  const [articleMetadata, setArticleMetadata] = useState(null);
  const { getAuthCookies } = useAuthApi();

  /**
   * @param  {Object} articleSearchFormData - data from article search form
   */
  const getArticles = useCallback(
    (articleSearchFormData, pageNum, pageSize) => {
      getArticlesData(
        `${BESSIE_API}/articles?${stringify(articleSearchFormData, {
          arrayFormat: 'comma',
        })}&pageNum=${pageNum}&perPage=${pageSize}`
      );
    },
    [getArticlesData]
  );

  /**
   * @param  {string} articleId - article ID
   */
  const getArticle = useCallback(
    (articleId) => {
      getArticleData(`${BESSIE_API}/article/${articleId}`);
    },
    [getArticleData]
  );

  /**
   * @param  {string} articleId - article ID
   */
  const getArticleView = useCallback(
    (articleId, versionId) => {
      if (versionId === 'null') {
        getArticleViewData(`${EDITORIAL_API}/ecm/articles/${articleId}/html`);
      } else getArticleViewData(`${EDITORIAL_API}/ecm/articles/${articleId}/versions/${versionId}/html`);
    },
    [getArticleViewData]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   */
  const addArticle = useCallback(
    (requestBody) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      addNewArticle(`${BESSIE_API}/article`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [addNewArticle, getAuthCookies]
  );

  /**
   * @param  {Object} requestBody - request body for API endpoint (not JSON)
   * @param  {string} articleId - article ID
   */
  const editArticle = useCallback(
    (requestBody, articleId) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      editArticleData(`${BESSIE_API}/article/${articleId}`, {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [editArticleData, getAuthCookies]
  );

  /**
   * @param  {string} articleId - article ID which has to be deleted
   */
  const deleteArticle = useCallback(
    (articleId) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      deleteArticleData(`${BESSIE_API}/article/${articleId}`, {
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [deleteArticleData, getAuthCookies]
  );

  /**
   * @param  {Object} majorVersionId - major version ID
   * @param  {string} articleId - article ID
   */
  const updateArticle = useCallback(
    (articleId, majorVersionId) => {
      const { encodedTicketFromCookie } = getAuthCookies();

      updateArticleVersionData(`${BESSIE_API}/article/${articleId}/version/${majorVersionId}`, {
        method: 'PATCH',
        body: JSON.stringify({ indexed: true }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: encodedTicketFromCookie,
        },
      });
    },
    [getAuthCookies, updateArticleVersionData]
  );

  const getRecentArticles = useCallback(() => {
    getRecentArticlesData(`${BESSIE_API}/articles/recent?perPage=${API_MAX_PER_PAGE}`);
  }, [getRecentArticlesData]);

  // Based on article data from response create a custom
  // data object for article metadata section
  useEffect(() => {
    if (articleData) {
      setArticleMetadata(buildArticleMetadata(articleData));
    }
  }, [articleData]);

  useEffect(() => {
    if (editData) {
      setArticleMetadata(buildArticleMetadata(editData));
    }
  }, [editData]);

  return {
    getArticles,
    articlesData,
    articleMetadata,
    errorGettingArticles,
    loadingArticles,
    abortGettingArticles,
    addArticle,
    addArticleData,
    errorAddingArticle,
    addingArticle,
    getArticle,
    articleData,
    errorGettingArticleData,
    loadingArticleData,
    getArticleView,
    articleViewData,
    errorGettingArticleViewData,
    loadingArticleViewData,
    editArticle,
    editData,
    errorEditingArticle,
    editingArticle,
    deleteArticle,
    deleteData,
    errorDeletingArticle,
    deletingArticle,
    updateArticle,
    updateData,
    errorUpdatingArticle,
    updatingArticle,
    getRecentArticles,
    recentArticlesData,
    errorGettingRecentArticles,
    loadingRecentArticles,
  };
};

export default useArticleApi;
