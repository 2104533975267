import React, { useEffect } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import recentArticlesPageColumns from './RecentArticlesTable.columns';
import { API_MAX_PER_PAGE } from '../../../App.constants';
import DefaultTable from '../../../components/DefaultTable/DefaultTable';
import stringFilterType from '../../../components/DefaultTable/DefaultTable.utils';
import DefaultTableControls from '../../../components/DefaultTableControls/DefaultTableControls';
import {
  setRecentArticlesTableConfigPageIndex,
  setRecentArticlesTableConfigPageSize,
} from '../../../store/action-creators';

const RecentArticlesTable = ({ data }) => {
  const numOfRows = data.length;
  const dispatch = useDispatch();
  const pageIndexFromRedux = useSelector((state) => state.recentArticlesTablePageIndex);
  const pageSizeFromRedux = useSelector((state) => state.recentArticlesTablePageSize);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data,
      columns: recentArticlesPageColumns,
      initialState: {
        pageIndex: pageIndexFromRedux,
        pageSize: pageSizeFromRedux,
      },
      filterTypes: stringFilterType,
      manualPagination: false,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    dispatch(setRecentArticlesTableConfigPageIndex(pageIndex));
  }, [pageIndex, dispatch]);

  useEffect(() => {
    dispatch(setRecentArticlesTableConfigPageSize(pageSize));
  }, [dispatch, pageSize]);

  return (
    <div className="mt-3 overflow-visible">
      {numOfRows < API_MAX_PER_PAGE ? (
        <p>
          Recent articles found: <strong>{numOfRows}</strong>
        </p>
      ) : (
        <p>
          Recent articles found: <strong>{API_MAX_PER_PAGE}</strong> (API max), from <strong>{numOfRows}</strong>.
        </p>
      )}
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
      <DefaultTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
      />
      <DefaultTableControls
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        numOfPages={pageOptions.length}
        pageSize={pageSize}
        setPageSize={setPageSize}
        numOfRows={numOfRows}
      />
    </div>
  );
};

RecentArticlesTable.defaultProps = {
  data: [],
};

RecentArticlesTable.propTypes = {
  data: PropTypes.shape(),
};

export default RecentArticlesTable;
